<template>
  <div class="m_container">
    <header id="header">
        <img src="~@/assets/image/mobile/mlogo.png" alt="">
    </header>
    <section id="section">
        <div class="banner_cont">
            <swiper class="banner-swiper" :options="swiperOption" ref="bannerSwiper" v-if="sweiperlength">
                <swiper-slide v-for="(item,index) in bannerList" :key="index">
                    <img :src="item.path_url" alt="">
                </swiper-slide>
                <div class="swiper-pagination index-swiper"  slot="pagination"></div>
            </swiper>
        </div>
        <div class="title">
            <h3><span>一站式</span>解决<br>您的视频素材需求</h3>
        </div>
        <!-- 素材类型 -->
        <div class="source_type">
            <div class="source_detail">
                <div class="music_source source" @click="goPage('musiclist')">
                    <!-- <span>音乐</span> -->
                </div>
                <div class="soundEffect_source source" @click="goPage('voicelist')">
                    <!-- <span>音效</span> -->
                </div>
                <div class="video_source source" @click="goPage('videolist')">
                    <!-- <span>视频</span> -->
                </div>
                <div class="AI_source source" @click="goPage('ailist')">
                    <!-- <span>AI语音</span> -->
                </div>
            </div>
        </div>
<!--        <div class="prompt-text">-->
<!--            功能持续开发中，敬请期待…-->
<!--        </div>-->
        <div class="open_memeber_btn" @click="openMember()">
            开通会员
        </div>
    </section>

    <!-- 进群送会员 -->
    <div class="member_cont"
    style="background:red"
    @click="dialogVisiblemember=true">
      <img src="@/assets/image/give_member1.png" alt="" class="member_icon">
      <span style="color:#fff">进群领会员</span>
    </div>

    <!-- 扫码进群 -->
    <el-dialog
      class="Mdialog-for-erweima"
      :visible="dialogVisiblemember"
      :destroy-on-close="false"
      :show-close="false"
      :append-to-body="false"
      :before-close="handleBeforeClose"
      center>
      <div class="Merweima_dialog">
        <div class="Merweima_content">
          <img src="@/assets/image/mobile/m_erweima.png">
        </div>
      </div>
    </el-dialog>

    <!-- 创作者扶持活动弹框 -->
    <!--<el-dialog
      class="Mdialog-for-creator"
      :visible="dialogVisiblecreator"
      :destroy-on-close="false"
      :show-close="false"
      :append-to-body="false"
      :before-close="handleClosecreator"
      center>
      <div class="creator_dialog">
        <div class="creator_content">
          <img src="@/assets/image/mobile/m_creator_dialog.png" @click="openMember">
        </div>
      </div>
    </el-dialog>-->

    <!--开通会员--图片弹框-->
    <!--<el-dialog
      class="Mdialog-for-creator"
      :visible="visibleVip"
      :show-close="false"
      :append-to-body="false"
      :before-close="handleClosevip"
      center>
      <div class="creator_dialog">
        <div class="creator_content">
          <img src="@/assets/image/mobile/m_creator_dialog.png" @click="openMember">
        </div>
      </div>
    </el-dialog>-->

    <!-- 建言献策 -->
    <!-- <div class="feedback_cont"
    @mouseover="dialogVisiblefeedback = true">
      <img src="@/assets/image/feedback.png" alt="" class="menu_icon">
      <span>建言献策</span>
    </div> -->
     <!-- 意见反馈弹框 -->
    <!-- <el-dialog
      class="dialog-for-feedback"
      :visible="dialogVisiblefeedback"
      :destroy-on-close="false"
      :show-close="false"
      :append-to-body="false"
      :before-close="handleBeforeClosefeed"
      center
    >
      <div class="feedback_dialog">
        <div class="feedback_content">
          <h3>欢迎吐槽，采纳有奖</h3>
          <img src="./assets/image/feedback_erweima.png">
        </div>
      </div>
    </el-dialog> -->

    <!-- 客服咨询 -->
    <div class="zixun_cont"
    onclick="qimoChatClick();">
      <img :src="zixun_url" alt="" class="zixun_icon">
      <span>咨询客服</span>
    </div>

    <!-- 助力推广赠人玫瑰弹窗 -->
    <div class="share_dialog" ref="wrapper"  v-show="showHelpDialog" @click="closeDialog">
      <div class="share_cont">
        <div class="share_detail" ref="guide">
          <p>您正在参加推广分享送会员活动<br>购买任意会员后<br>分享人可免费获得月会员一个</p>
          <img src="@/assets/image/mobile/btn.png" @click="handelHelp" class="btn">
        </div>
      </div>
    </div>

    <!-- 支付成功弹窗 -->
    <div class="paySuccess_dailog" v-show="showSuccessDialog">
      <div class="paySuccess_cont">
        <div class="pay_detail">
          <p class="pay_title">恭喜</p>
          <p class="confirm_text1">您已成为PlayList尊贵的会员</p>
          <p class="confirm_text2">请使用PC端Web浏览器进行素材下载<br>可在浏览器直接搜索“PlayList”</p>
          <button @click="closeSuccessDialog">确认</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import { setStorageH5shareId } from '@/utils/sessionStroge.js';
import { userIncline } from '@/api/member.js';
import { mapGetters } from 'vuex'
import { getBannerlist } from  '@/api/mobile.js';
import router from '../../router';
export default {
  components:{
    swiper,
    swiperSlide
  },
  data(){
    const self = this;
    return{
      visibleVip: false,
      bannerList:[],
      dialogVisiblemember: false,
      dialogVisiblefeedback: false,
      dialogVisiblecreator: false,
      zixun_url: require('@/assets/image/kefu.png'),
      swiperIndex: 1,
      // banner轮播配置项
      swiperOption: {
        loop: true,
        autoplay: false,
        // autoplay: {
        //     delay: 2000,
        //     stopOnLastSlide: false,
        //     disableOnInteraction: false
        // },
        centeredSlides: true,
        slidesPerView: 1.2, // 设置slider容器能够同时显示的slides数量
        slidesPerGroup: 1, // 定义slides的数量多少为一组
        spaceBetween: 5, // 间距
        effect: 'slide',
        preventClicksPropagation: true, // 拖动swiper的时候禁止点击事件
        pagination: {
          el: '.swiper-pagination',
        },
        on: {
          slideChangeTransitionEnd: function () {
            // 这里的this指向swiper实例，activeIndex是里面的一个内置属性，也就是激活slide的下标（从0开始）
          },
          click: function () {
            // this.realIndex 获取当前swiper的真实索引
            self.bannerEvent(this.realIndex)
          },
        },
      },
      share_id: '' // 分享用户id
    }
  },
  computed:{
    ...mapGetters({
      userInfo: 'userInfo'
    }),
    // 是否展示分享助力弹窗
    showHelpDialog(){
      return this.$store.state.mobile.showHelpDialog
    },
    sweiperlength () {
      return this.bannerList.length
    },
    // 支付成功弹窗是否显示
    showSuccessDialog(){
      return this.$store.state.mobile.showSuccessDialog
    }
  },
  async created(){
    //控制只有一张图片的时候不自动轮播
    this.swiperOption.autoplay = this.bannerList.length !== 1 ? {
      delay: 3000,
      stopOnLastSlide: false,
      disableOnInteraction: false
    } : false;
    // 判断是否为分享打开的链接 获取分享人user_id
    this.share_id = this.GetQueryString('user_id')
    if(this.share_id && this.share_id !== null){
      setStorageH5shareId(this.share_id);
      window.localStorage.setItem('shareMobile_token', this.share_id)
      // 初始化绑定助力用户 防止被分享用户产生购买行为还无效的情况
      const param = {
        share_token: this.share_id
      }
      await userIncline(param);
    }
    if(!window.sessionStorage.getItem('isShowMcreator')){
      this.dialogVisiblecreator = true;
      window.sessionStorage.setItem('isShowMcreator', true)
    } else {
      this.dialogVisiblecreator = false;
    }
    this.getBannerdata();
    // 开通vip会员弹框显示
    // this.visibleVip = window.sessionStorage.getItem('visibleVip') === 'false' ? false : true;
  },
  mounted(){
    // 判断用户是否登录 如果未登录且是通过推广分享进来的用户需要先进行登录
    if(!this.userInfo && this.share_id){
      this.$router.push({ path: '/login' })
    }
  },
  methods:{
    handleClosevip(){
      this.visibleVip = false;
      // window.sessionStorage.setItem('visibleVip', 'false');
    },
    /**
     * 获取banner列表
     */
    async getBannerdata(){
      const params = {
          limit: 10
      }
      const res = await getBannerlist(params)
      if(res.code == 0){
          const {list} = res.data;
          this.bannerList = list
      }
    },
    /**
     * 获取地址栏参数
     */
    GetQueryString(name){
      var reg= new RegExp("(^|&)"+name+"=([^&]*)(&|$)","i");
      var r = window.location.search.substr(1).match(reg);
      if(r!=null)
      return decodeURI(r[2]);
      return null
    },
    /**
     * 关闭助力弹窗
     */
    closeDialog(e){
      if(this.$refs.wrapper){
        const isSelf = this.$refs.guide.contains(e.target);
        if(!isSelf){
          this.$store.dispatch('mobile/setDialogHelpstatus', false)
        }
      }
    },
    closeSuccessDialog(){
      this.$store.dispatch('mobile/setDialogSuccess', false);
    },
    /**
     * 关闭创作者活动弹框
     */
    handleClosecreator(){
      this.dialogVisiblecreator = false;
    },
    // 点击banner
    bannerEvent(index){
      let link = this.bannerList[index]?.link || null;
      if(link.search("doubleElevenH5") > -1){
        if(!this.userInfo){
          this.$router.push({ path:'/login' });
          return;
        }
      }
      window.localStorage.setItem('banner_id', this.bannerList[index].banner_id)
      window.location.href = this.bannerList[index].link;
    },
    /**
     * 进群送会员弹窗
     */
    handleBeforeClose(){
      this.dialogVisiblemember = false;
    },
    /**
     * 立即助力
     */
    async handelHelp(){
      const share_token = window.localStorage.getItem('shareMobile_token');
      const param = {
        share_token: share_token
      }
      const res = await userIncline(param);
      if(res.code == '0'){
        this.$router.push({ path: '/mobile/member' })
        this.$store.dispatch('mobile/setDialogHelpstatus', false)
      }else{
        this.$store.dispatch('mobile/setDialogHelpstatus', false)
      }
    },
    /**
     * 开通会员
     */
    openMember(){
      this.handleClosevip();
      if(!this.userInfo){
        this.$router.push({ path:'/login' })
      }else{
        this.$router.push({ path: '/mobile/member' })
      }
    },
    // 跳转列表页面
    goPage(name){
      router.push({
        path: `/mobile/${name}`
      })
    }
  }
}
</script>
<style lang="scss" scoped>
*{
    margin: 0;
    padding: 0;
}
.m_container{
    width: 100%;
    height: 100%;
    #header{
        width: 100%;
        height: .88rem;
        background: #FFFFFF;
        box-shadow: 0rem 0.04rem .24rem 0rem rgba(0,0,0,0.07);
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            width: 1.7rem;
        }
    }
    #section{
        width: 100%;
        margin-top: .4rem;
        .banner_cont{
            width: 100%;
            .banner-swiper{
                /deep/.swiper-slide{
                    background: rgba(255, 255, 255, 0.8);
                    transition: 100ms;
                    -webkit-transform: scale(0.95);
                    // pointer-events: none;
                    img{
                        width: 100%!important;
                        height: 3.36rem!important;
                        border-radius: .16rem;
                        box-shadow: 0rem 0rem .14rem 0rem rgba(51,51,51,0.36);
                    }
                }
                .swiper-slide-active,
                .swiper-slide-duplicate-active {
                    transform: scale(1);
                }
                .swiper-pagination-bullets{
                    bottom: 6px!important;
                    /deep/.swiper-pagination-bullet{
                        display: inline-block!important;
                        width: .24rem!important;
                        height: 0.08rem!important;
                        background: rgba(255, 255, 255, 0.48)!important;
                        border-radius: 0.06rem!important;
                        &.swiper-pagination-bullet-active{
                            display: inline-block!important;
                            width: .4rem!important;
                            height: 0.08rem!important;
                            background: #E52A0D!important;
                            border-radius: 0.06rem!important;
                            -webkit-transition: all .3s ease-in-out;
                        }
                    }
                }
                // @keyframes move {
                //     0%{
                //         -webkit-transform: translate(0);
                //     }
                //     50%{
                //          -webkit-transform: translate(10);
                //     }
                //     100%{
                //          -webkit-transform: translate(50);
                //     }
                // }
            }
        }
        .title{
            text-align: center;
            padding-top: .48rem;
            h3{
                font-size: .28rem;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: rgba(51, 51, 51, 1);
                line-height: .4rem;
                span{
                    color: #E52A0D;
                }
            }
        }
        .source_type{
            width: 100%;
            padding: 0rem .4rem;
            .source_detail{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                .source{
                    width: 48%;
                    height: 1.92rem;
                    margin-top: .32rem;
                    text-align: center;
                    line-height: 1.92rem;
                    span{
                        display: inline-block;
                        font-size: .36rem;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        color: #FFFFFF;
                    }
                    &.music_source{
                        background: url('~@/assets/image/mobile/music_bg.png') no-repeat;
                        background-size: 100% 100%;
                    }
                    &.soundEffect_source{
                        background: url('~@/assets/image/mobile/soundEffect_bg.png') no-repeat;
                        background-size: 100% 100%;
                    }
                    &.video_source{
                        background: url('~@/assets/image/mobile/video_bg.png') no-repeat;
                        background-size: 100% 100%;
                    }
                    &.AI_source{
                        background: url('~@/assets/image/mobile/AI_bg.png') no-repeat;
                        background-size: 100% 100%;
                    }
                }
            }
        }
        .prompt-text{
            text-align: center;
            font-size: .22rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(51, 51, 51, 0.64);
            margin-top: .16rem;
        }
        .open_memeber_btn{
            margin: .96rem auto 0rem;
            width: 4rem;
            height: .8rem;
            text-align: center;
            line-height: .8rem;
            background: #E52A0D;
            box-shadow: 0rem 0.08rem .14rem 0rem rgba(255,47,15,0.36);
            border-radius: .4rem;
            font-size: .3rem;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
        }
    }
    .zixun_cont,.member_cont,.feedback_cont{
        position: fixed;
        right: 0.08rem;
        width: 1.2rem;
        height: 1.2rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.09);
        border-radius: 0.05rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        border-radius: 6px;
        .member_icon,.zixun_icon{
            width: .48rem;
            height: .48rem;
        }
        span{
            display: block;
            font-size: .2rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            padding-top: 0.03rem;
        }
    }
    .zixun_cont{
        bottom: .28rem!important;
        span{
            color: #B2B2B2;
        }
    }
    .member_cont{
        bottom: 1.72rem;
    }
    .feedback_cont{
        bottom: 2.88rem;
    }
    .Mdialog-for-erweima,.Mdialog-for-creator{
        // position: fixed!important;
        z-index: 5000;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        /deep/.el-dialog {
            background: none!important;
            width: 5.72rem;
            height: 6.36rem;
            margin-top: 0!important;
            box-shadow: none!important;
            position: relative;
            .Merweima_dialog,.creator_dialog{
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                background: none;
                align-items: center;
                .Merweima_content{
                    width: 5.72rem;
                    height: 6.36rem;
                    position: absolute;
                    top: 0;
                    img{
                        width: 5.72rem;
                        height: 6.36rem;
                    }
                }
            }
        }
    }
    .Mdialog-for-creator{
        /deep/.el-dialog{
            width: 6rem;
            height: 5.5rem;
            .creator_content{
                width: 6rem;
                // height: 5.98rem;
                position: absolute;
                top: 0;
                img{
                    width: 100%;
                }
            }
        }
    }
    // 分享助力弹窗
    .share_dialog{
        z-index: 2000;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.6);
        .share_cont{
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .share_detail{
                width: 6rem;
                height: 5.98rem;
                background: url('~@/assets/image/mobile/share_dialog.png') no-repeat;
                background-size: 100% 100%;
                text-align: center;
                position: relative;
                p{
                    padding-top: 4.24rem;
                    font-size: .28rem;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: rgba(255, 255, 255, 0.86);
                    line-height: .4rem;
                }
                .btn{
                    width: 3.2rem;
                    position: absolute;
                    left: 50%;
                    margin-left: -1.6rem;
                    bottom: -.5rem;
                }
            }
        }
    }
     // 开通会员弹窗
    .paySuccess_dailog{
      z-index: 2000;
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      background: rgba(0,0,0,.6);
      .paySuccess_cont{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .pay_detail{
          width: 5.6rem;
          height: 5.08rem;
          background: url('~@/assets/image/mobile/member_confirm_bg.png') no-repeat;
          background-size: contain;
          text-align: center;
          .pay_title{
            font-size: .48rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: .48rem;
            letter-spacing: 0.02rem;
            padding-top: .52rem;
          }
          .confirm_text1{
            font-size: .36rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #E52A0D;
            padding-top: .2rem;
            padding-top: .76rem;
          }
          .confirm_text2{
            font-size: .26rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: rgba(51, 51, 51, 0.8);
            line-height: .39rem;
            padding-top: .24rem;
          }
          button{
            outline: none;
            border: none;
            margin: .68rem auto 0;
            width: 2.72rem;
            height: .72rem;
            background: #E52A0D;
            border-radius: .4rem;
            font-size: .3rem;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            letter-spacing: 0.04rem;
          }
        }
      }
    }

}
</style>
